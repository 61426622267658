
import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver } from 'vee-validate';

import { UserService } from '@/services';
import { UserModule } from '@/store/modules';
import { IAuthUser, IUserUpdateFields } from '@/types/user.types';

import UserAvatar from '@/components/UserAvatar.vue';

@Component({
  components: {
    UserAvatar,
    ValidationObserver
  }
})
export default class ProfilePage extends Vue {
  udata = {
    email: this.user?.email,
    firstName: this.user?.firstName,
    lastName: this.user?.lastName,
    postalCode: this.user?.postalCode,
    referralCode: this.user?.referralCode,
    mobilePhoneNumber: this.user?.mobilePhoneNumber
  };

  receiveNotificationSms = this.user?.notificationSettings?.sms || false;

  get user(): IAuthUser | null {
    return UserModule.authenticatedUser;
  }

  get mobileFormated() {
    return Vue.filter('phone')(this.udata.mobilePhoneNumber);
  }

  set mobileFormated(input: string) {
    const clean = input.replaceAll(' ', '');
    this.udata.mobilePhoneNumber = clean.length < 11 ? clean : this.udata.mobilePhoneNumber;
  }

  async updateUserData(): Promise<void> {
    // We cannot send an empty string or null to the API
    // Currently user is not able to delete their phone number once onboarded
    // (it can only be overwritten)
    this.udata.mobilePhoneNumber = this.udata.mobilePhoneNumber || undefined;
    try {
      await UserService.updateProfile(this.udata as IUserUpdateFields);
      this.$notifySuccess('Your profile was saved successfully');
      let user = await UserService.fetchUser();
      UserModule.setUser(user);
    } catch (e) {
      this.$notifyError("Your profile hasn't been saved. Please try again");
    }
  }
}
